<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="header">
      <h1>智慧楼宇</h1>
      <p style="text-indent: 2em">
        智能楼宇系统集成建设与管理，以楼宇集成技术结合物联网及云技术，提供一个涵盖监视、控制、管理一体化的综合信息集成管理平台，为建筑能效优化和物业管理营运提供一站式解决方案。
      </p>
    </div>
    <div class="survey">
      <h5>智慧楼宇分类</h5>
      <div class="surveyTitle">智慧写字楼</div>
      <div>独立办公楼，可租赁给各个公司</div>
      <div class="surveyTitle">智慧园区</div>
      <div>由多个写字楼组成园区，</div>
      <div>且有停车场、餐饮等配套设施</div>
      <div class="surveyTitle">智慧联合办公空间</div>
      <div>服务于创业型企业，</div>
      <div>企业间共用会议室、</div>
      <div>打印机等公共设施与空间</div>
      <div class="surveyTitle">智慧酒店</div>
      <div>提高城市治理能力现代化水平，</div>
      <div>实现城市可持续发展</div>
      <div class="surveyTitle">智慧政企</div>
      <div class="surveyBottom">政府企业等现代化智能管理办公大楼</div>
    </div>
    <div class="framework">
      <h2 class="title">平台架构</h2>
      <div class="img3">
        <img
          style="width: 100%; height: 100%"
          @click="onYuLan(img)"
          :src="img"
          alt=""
        />
      </div>
    </div>
    <h2 class="title">功能场景</h2>
    <div class="scene">
      <van-tabs
        v-model="active"
        swipeable
        :swipe-threshold="3"
        title-active-color="#365EF1"
        background="#F2F3F8"
      >
        <van-tab v-for="(item, index) in list" :key="index" :title="item.name">
          <img :src="item.img" alt="" class="sceneImg" />
          <h1 class="title">{{ item.name }}</h1>
          <div v-if="item.data">
            <div v-for="(el, i) in item.data" :key="i">
              <div class="classification">{{ el }}</div>
            </div>
          </div>
          <div v-if="item.text1">
            <p>{{ item.text1 }}</p>
            <p>{{ item.content }}</p>
          </div>
          <p v-else>{{ item.text }}</p>
          <div class="detail" @click="go(item.id)">查看详情</div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
import { ImagePreview } from "vant";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return {
      img: require("../../assets/image/buildingImg/组 177.png"),
      active: 0,
      list: [
        {
          id: 0,
          name: "数据采集",
          img: require("../../assets/assets/buildingImg/img2.png"),
          text: "现场采集内容覆盖楼宇自控、门禁、智能空调、ups、电梯、 变配电、消防等系统。其中主要关注核心系统运行状况、主要 能耗管网状态、环境介质质量监测等数据。将全楼宇的智能控 制系统的实时状态采集进入系统，供数据监视、存储、报警、 分析、计算、统计平衡等使用。",
        },
        {
          id: 1,
          name: "能耗分析",
          img: require("../../assets/assets/buildingImg/img3.png"),
          text: "通过能源消费结构，楼层能耗对比，重点耗能设备分析等多种分析方式，报表分析可以帮助物业管理人员计算特定房间或人均能耗，实现自主能源审计管理。报表可以自动生成，按实际需要实现手动或自动打印，供调度和运行管理人员使用。",
        },
        {
          id: 2,
          name: "报警管理",
          img: require("../../assets/assets/buildingImg/img4.png"),
          data: [
            "设备报警",
            "环境质量报警",
            "电源故障报警",
            "网络故障报警",
            "报警级别设定",
          ],
        },
        {
          id: 3,
          name: "设备管理",
          img: require("../../assets/assets/buildingImg/img5.png"),
          text: "能源管理系统的对象覆盖楼宇的各种大型能源设施，通过对能源设备的运行、异常、故障和事故状态实时监视和记录。通过技改和加强维护，指导维护保养工作，提高能源设备效率，实现能源设备闭环管理。",
        },
      ],
    };
  },

  methods: {
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
    go(id) {
      this.$router.push({
        path: "/m_buildingDetail",
        query: {
          buildingId: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  box-sizing: border-box;
  margin-top: -27px;
  .title {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 20px 0;
  }
  .header {
    height: 480px;
    background: url("../../assets/assets/buildingImg/bg.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 10px;
    > h1 {
      font-size: 20px;
      font-weight: normal;
      color: #fff;
      padding-top: 100px;
      margin: 0;
    }
    > p {
      padding: 20px 10px 0 0px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 30px;
      color: #ffffff;
    }
  }
  .survey {
    > h5 {
      height: 40px;
      background: #3651d8;
      border-radius: 0px;
      font-size: 16px;
      font-weight: normal;
      color: #ffffff;
      margin: 0;
      line-height: 40px;
      padding-left: 10px;
    }
    > div {
      background-color: #fff;
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      font-weight: normal;
      color: #6b6b6b;
      text-align: center;
    }
    .surveyTitle {
      height: 22px;
      font-size: 18px;
      font-weight: normal;
      color: #1864e3;
      padding: 14px 0;
    }
    .surveyBottom {
      padding-bottom: 40px;
    }
  }
  .framework {
    .img3 {
      width: calc(100% - 20px);
      height: 120px;
      background: url("../../assets/image/buildingImg/组 177.png") no-repeat;
      background-size: contain;
      margin: 0px 0 0 10px;
    }
  }
  .scene {
    width: calc(100% - 20px);
    margin-left: 10px;
    background-color: #fff;
    padding-bottom: 20px;

    .sceneImg {
      width: 100%;
      height: 180px;
      padding-top: 20px;
      background-color: #f2f3f8;
    }
    p {
      text-indent: 2em;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      padding: 0 10px;
    }
    .detail {
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid rgba(54, 94, 241, 0.5);
      border-radius: 36px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      opacity: 1;
      position: relative;
      left: 0;
      right: 0;

      margin: auto;
      margin-top: 20px;
    }
    h4 {
      font-size: 14px;
      text-align: center;
      color: #333;
    }
    .classification {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #555555;
      text-align: center;
    }
  }
}
::v-deep .van-tabs__line {
  background-color: #365ef1;
}
</style>
